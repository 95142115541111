.lblHeaderConfig {
    position: fixed !important;
    left: 40px !important;
    font-size: 24px;
    top: 130px;
}

.div-form-profile {
    position: relative;
    width: 800px;
    margin-top: 50px;
}

.div-form-profile label {
    position: relative;
    left: -50px;
    width: 140px !important;
}

.div-form-profile input {
    position: relative;
    left: -40px;
    width: 546px;
}

.uploadComponent {
    display: none !important;
}

.checkExpirar {
    position: relative;
    top: -7px;
}


.labelExpirar {
    position: relative !important;
    left: -140px !important;
}

.inputExpirar .ant-input-number {
    position: relative !important;
    left: -150px !important;
}

.inputExpirar .ant-input-number-input-wrap {
    position: relative !important;
    left: 35px !important;
    width: -10px !important;
}

.inputExpirar .ant-input-number-input:disabled {
    position: relative !important;
    left: 35px !important;
    width: 5px !important;
}

.divConfigSpin {
    position: absolute;
    width: 100%;
    left: 30px;
    float: left;
    display: grid;
    top: 160px;
}



