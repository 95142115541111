.ant-layout-has-sider {
    max-height: 93vh !important;
}

.ant-layout-sider {
    overflow-y: scroll !important;
    max-height: 93vh;
}

.logo {
    margin: 0.625rem;
    height: 5rem;
    min-width: 10%;
    color: white;
    font-family: 'Trocchi', serif;
    font-size: 2.5vw;
    float: left;
}

.center {
    margin: auto;
    color: white;
    justify-content: center;
    font-family: 'Trocchi', serif;
    font-size: 2.5vw;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.center img {
    position: relative;
    text-align: center;
    padding: 0.313rem;
    width: 3.125rem;
    margin-left: 0.313rem;
}

.logoMoub {
    position: relative;
    text-align: center;
    padding: 0px;
    width: 122px;
    margin-left: 0px;
    top: -10px;
    left: -7px;
    content: url("https://storage.googleapis.com/moub-bucket/moub-logo.png");
}

.logoRegina {
    position: relative;
    text-align: center;
    padding: 0px;
    width: 70px;
    margin-left: 0px;
    top: 5px;
    left: 7px;
    content: url("https://storage.googleapis.com/moub-bucket/regina-logo.png");
}

table {
    margin-bottom: 3.125rem;
}

.card-profile {
    position: relative;
    top: 0px;
    left: -5px;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    width: 250px;
    background-color: #e6e6e6;
}

.card-profile>label {
    font-size: 0.75rem;
    display: block;
    margin-bottom: 0;
    margin-right: 0.313rem;
    text-align: end;
}

.pspan {
    position: relative;
    margin-left: 0.313rem;
    margin-right: 0.313rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.img-profile {
    width: 120px !important;
    border-radius: 50%;
    position: relative;
    top: 10px;
    margin-bottom: 15px;
}

.title-profile {
    color: rgb(87, 86, 86);
    font-size: 1.125rem;
    position: relative;
    top: 5px;
}

.buttonProfile {
    border: none;
    outline: 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    width: 80%;
    top: -0.75rem;
}

.buttonProfileExit {
    border: none;
    outline: 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    width: 80%;
    top: -1rem;
}


.a-profile {
    text-decoration: none;
    font-size: 1.375rem;
    color: black;
}

.buttonProfile:hover,
.a-profile:hover {
    opacity: 0.7;
}

.iconPageLayout {
    position: relative;
    top: -0.313rem;
}

.profileAvatar {
    border: 0.125rem solid #D2D2D2;
    cursor: pointer;
}

.antSubMenu {
    padding-left: 2rem !important;
}


.actionsMoub {
    color: #9B619D !important;
    text-decoration: underline !important;
}

.actionsMoub:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
}


.btnNewRecord {
    z-index: 999;
}
