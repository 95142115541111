.divSaldoPorCartao {
    overflow-y: scroll;
    overflow-x: auto;
    max-height: 100vh;
}

.containerMovimentacao {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerMovimentacao label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerMovimentacao button {
    margin-left: 10px;
}

.fff {
    background: #fff;
}

.eee {
    background: #eee;
}

.ddd {
    background: #ddd;
}

.Cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 40px 20px 40px;
    height: 10rem;
}

.labelNomeBeneficiario {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
}

.labelDebito {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
    margin-top: 6px;
    color: rgb(255, 51, 0);
}

.labelCredito {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
    margin-top: 6px;
    color: rgb(68, 164, 59);
}

.labelSaldo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 28px;
    font-weight: 700;
    margin-top: 6px;
    color: white;
}

.cardSaldo {
    background-color: rgb(45, 189, 74);
}

.ant-card-head-title {
    font-size: 20px !important;
}

.nota {
    margin: 20px 40px 20px 40px;
}

.tableEstabelecimentos {
    margin: 20px 40px 20px 40px;
}

.subtituloMovimentos {
    margin: 20px 40px 0px 40px;
    font-size: 16px;
    font-weight: 600;
}