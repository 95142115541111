.containerClientesConvenio {
    margin-top: 10px;
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerClientesConvenio label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerClientesConvenio button {
    margin-left: 10px;
}

.tableClienteConvenio {
    margin-top: 20px;
}