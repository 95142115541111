@media only screen and (max-width: 600px) {
    body {
        width: 100% !important;
    }

    .divTable {
        width: 100% !important;
    }
}

.ant-table {
    
    display: block;

    @media screen and (max-width: 600px) {
        &-thead {
            display: none !important;
        }

        &-thead>tr,
        &-tbody>tr {

            th,
            td {
                &:first-of-type {
                    padding-top: 1rem !important;
                }

                &:last-of-type {
                    padding-bottom: 1rem !important;
                }
            }

            >th,
            >td {
                display: block !important;
                width: auto !important;
                border: none !important;
                padding: 0 1rem !important;
                font-size: 1.1rem !important;

                &:last-child {
                    border-bottom: 1px solid #eee !important;
                }
            }
        }
    }
}


.icon {
    position: relative;
    top: -3px;
}

.btnGerente {
    color: white !important;
    background-color: rgb(96, 177, 76) !important;
}

.btnVendedor {
    color: white !important;
    background-color: rgb(57, 131, 201) !important;
}