.paragrafoAviso {
    background-color: #cc3300;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.labelAviso {
    line-height: 1.2;
}

.wrapInputValorCredito {
    border: solid 1px;
    border-radius: 3px !important;
    width: 120px;
    height: 30px;
    padding-left: 5px;
}

.formConvenio {
    background-color: white;

}