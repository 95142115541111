.capturaContainer {
  margin-top: 60px;
}

.capturaContainer .ant-progress-text {
  font-size: 10pt;
}

.capturaDivSeparadora {
  margin-left: 70px;
  margin-bottom: 10px;
}

.capturaDivSeparadoraCenter {
  margin-left: 70px;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.capturaDivSeparadora label {
  font-weight: bold;
}

.capturaDivSeparadoraAlinhadaDireita {
  position: fixed;
  top: 224px;
  left: 650px;
}

.bg-green {
  background-color: #00a65a !important;
  /* width: 250px; */
}


.capturaBtnProximo {
  margin-left: 10px;
  margin-top: 50px;
  display: table-row;
}

.valorCompraCaptura {
  border: solid 1px;
  border-radius: 3px !important;
  border-color: rgb(214, 211, 211);
  width: 220px;
  padding-left: 5px;
  margin-left: 10px;
  height: 30px;
}