.containerSaldos {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerSaldos label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerSaldos button {
    margin-left: 10px;
}

.tableSaldos {
    margin-top: 20px;
}

.totalDebitoViaCredito {
    font-weight: bold;
    font-size: 18px;
}

.totalDebitoViaCreditoRed {
    font-weight: bold;
    font-size: 18px;
    color: red;    
}