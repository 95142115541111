.containerHistoricoMovimento {
    height: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px gray;
}

.containerHistoricoMovimento label {
    margin-left: 10px;
    margin-right: 10px;
}

.containerHistoricoMovimento button {
    margin-left: 10px;
}

.tableHistoricoMovimentos {
    margin-top: 20px;
    padding-bottom: 30px;
}

.tableHistoricoMovimentos label {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.filtroHistoricoMovimento {
    margin-bottom: 20px;
}
